import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import Vaclav from "../../../images/reference/grohe/vaclav.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import { Medailon } from "@igloonet-web/shared-ui"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import Banner from "../../../images/reference/grohe/banner.jpg"
import Flowers from "../../../images/reference/grohe/flowers.jpg"
import Kitchen from "../../../images/reference/grohe/kitchen.jpg"
import Shower from "../../../images/reference/grohe/shower.jpeg"
import Logo from "../../../images/reference/grohe/logo.png"

const Grohe: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Reference na marketingové práce pro Grohe | igloonet.cz</title>
        <meta
          name="description"
          content="Konzultace a mentoring interních týmů pro klienty řešíme celkem často,
            ale tato akce pro finanční manažery pro nás byla svým záběrem i krátkou dobou
            na realizaci specifická."
        />
      </Helmet>
      <ReferenceHeader
        companyName="grohe"
        heading="Představení současných e‑commerce trendů pro finanční manažery z Evropských zemí"
        date="únor 2020"
      />
      <ReferenceImageText
        right
        img={Shower}
        eager
        alt="ilustrativní fotografie koupelny"
        logo={Logo}
        logoAlt="logo společnosti Grohe"
        link="//grohe.cz/"
      >
        <p>
          Konzultace a mentoring interních týmů pro klienty řešíme celkem často,
          ale tato akce pro finanční manažery pro nás byla svým záběrem i
          krátkou dobou na realizaci specifická.
        </p>
      </ReferenceImageText>
      <ReferenceImageText
        responsiveTextOrder
        img={Flowers}
        alt="ilustrativní fotografie kohoutků na vodu v koupelně"
      >
        <p>
          Pro Grohe stejně jako pro ostatní tradiční výrobce má online svět čím
          dál tím větší kouzlo. Na pravidelném setkání finančních manažerů z
          evropských poboček Grohe jsme proto představili současné trendy ve
          světě e‑commerce.
        </p>
        <p>
          Tím, že šlo o akci pro finanční manažery, jsme největší důraz kladli
          na vyhodnotitelnost jednotlivých aktivit – nejen na úrovni
          jednoduchého ROI výkonnostních kampaní, ale vyhodnocování v kontextu
          konkurence, brand awereness a share of voice v online prostředí.
        </p>
      </ReferenceImageText>

      <ReferenceImageText right img={Kitchen} alt="">
        <p>
          Český tým nejdříve prezentoval své kampaně, kterými ukázal možnosti
          provázání digitálních aktivit s tradiční outdoor formou – propojování
          e‑commerce světa s tím retailovým. My poté pokračovali třemi bloky:
        </p>
        <div className="px-4 py-5">
          <p>
            Český tým nejdříve prezentoval své kampaně, kterými ukázal možnosti
            provázání digitálních aktivit s tradiční outdoor formou –
            propojování e‑commerce světa s tím retailovým. My poté pokračovali
            třemi bloky:
          </p>
          <ol>
            <li>možnosti brandových aktivit v rámci digitálu;</li>
            <li>pokročilé možnosti měření a vyhodnocování;</li>
            <li>nové trendy v rámci e‑commerce.</li>
          </ol>
          <p>
            Akce nám přišla vydařená, tak doufáme, že jsme svou troškou do mlýna
            pomohli s „digitální evolucí“.
          </p>
        </div>
      </ReferenceImageText>

      <img src={Banner} loading="lazy" alt="" width="100%" height="auto" />

      <Medailon
        personName="Ing. Václav Kužílek"
        position="Marketing Activation Manager CZSK"
        img={Vaclav}
      >
        <p>
          Pro společnost GROHE je a vždy bude podstatné udržovat krok a tempo
          nastavené trhem, nejen proto abychom si dokázali udržet svou
          dominantní pozici leadera v oblasti sanity, ale také abychom celý obor
          pomáhali definovat, edukovat a zároveň určovat jeho dlouhodobé
          směřování. I proto jsme se rozhodli spojit síly s igloonetem v rámci
          edukace finačních manažerů GROHE, kteří právě nejčastěji rozhodují o
          strategických investicích.
        </p>
        <p>
          Zdokonalování jakýchkoliv procesů lze dosáhnout pouze tehdy, kdy se
          učíte od těch nejlepších v oboru, a proto byl coach Adam naše sázka na
          jistotu.
        </p>
        <p>
          Školení bylo názorné, interaktivní a postaveno na konkrétních datech,
          proto také sklidilo velký úspěch a my se tešíme na další společné
          výzvy.
        </p>
      </Medailon>
      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Grohe
